import React from 'react';
import ReactGA4 from "react-ga4";
import { shallow } from '@jwp/ott-common/src/utils/compare';
import type { Content } from '@jwp/ott-common/types/config';
import { useConfigStore } from '@jwp/ott-common/src/stores/ConfigStore';
import { useTranslation } from 'react-i18next';

import ShelfList from '../../containers/ShelfList/ShelfList';

import '../../styles/accessibility.scss';

const Home = () => {

  console.log('Home', { hitType: "pageview", page: window.location.href, title: "Home" });
  ReactGA4.initialize("G-PMLKM684JS");
  ReactGA4.send({ hitType: "pageview", page: window.location.href, title: "Home" });

  const { config } = useConfigStore(({ config, accessModel }) => ({ config, accessModel }), shallow);
  const content: Content[] = config?.content;
  const { t } = useTranslation('common');

  return (
    <>
      <h1 className="hideUntilFocus">{t('home')}</h1>
      <ShelfList rows={content} />
    </>
  );
};

export default Home;
