import React, { useMemo } from 'react';
import DOMPurify from 'dompurify';
import { marked } from 'marked';
import classNames from 'classnames';

import styles from './MarkdownComponent.module.scss';

type Props = {
  markdownString: string;
  className?: string;
  inline?: boolean;
  tag?: string;
  svgIcons?: Record<string, string>;
};

const MarkdownComponent: React.FC<Props> = ({ markdownString, className, tag = 'div', inline = false, svgIcons }) => {
  const customRenderer = {
    link(href: string, title: string, text: string) {
      const externalLink = /^(https?|www\.|\/\/)/.test(href || '');
      const attributes = [externalLink ? 'target="_blank"' : '', externalLink ? 'rel="noopener"' : '', title ? `title="${title}"` : '']
        .filter(Boolean)
        .join(' ');

      const iconPattern = /icon_([a-zA-Z0-9_]+)/; // Regular expression to detect icons
      const match = text.match(iconPattern);

      if (match) {
        const iconName = match[1]; // Extract the icon name
        const src = svgIcons[iconName]; // Ensure correct path to the SVG
        return `<a href="${href}" ${attributes}><img src="${src}" alt="${iconName}" /></a>`;
      }

      // If no icon pattern is found, return a regular hyperlink
      return `<a href="${href}" ${attributes}><label>${text}</label></a>`;
    },
  };

  // Apply the custom renderer to `marked`
  marked.use({ renderer: customRenderer });

  const sanitizedHTMLString = useMemo(() => {
    const parseDelegate = inline ? marked.parseInline : marked.parse;
    const dirtyHTMLString = parseDelegate(markdownString);

    return DOMPurify.sanitize(dirtyHTMLString, { ADD_ATTR: ['target', 'rel', 'src'] });
  }, [inline, markdownString]);

  return React.createElement(tag, {
    className: classNames(styles.markdown, className),
    dangerouslySetInnerHTML: { __html: sanitizedHTMLString },
  });
};

export default MarkdownComponent;
